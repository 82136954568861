import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/Layout';
import UniversalCard from '../components/molecules/UniversalCard';
import CourseSection from '../components/molecules/CoursesSection';
import CFS from '../components/molecules/CFS';
import Collapse from '../components/molecules/Collapse';
import Checkmark from '../components/atoms/Checkmark';
import { EN_SIGN_UP, INSCRIPCION_ROUTE } from '../constants/routes';

import imgSecSection from '../assets/images/pages/courses/discount-spanish-lessons/barcelona-view.jpg';
import imgGr from '../assets/images/pages/courses/curso-fele-intensivo/gr.svg';
import imgEpeea from '../assets/images/pages/courses/curso-fele-intensivo/epeea.svg';
import imgAm from '../assets/images/pages/courses/curso-fele-intensivo/am.svg';
import imgApyol from '../assets/images/pages/courses/discount-spanish-lessons/location.svg';
import imgReviewer from '../assets/images/pages/courses/discount-spanish-lessons/nicolas-horn-MTZTGvDsHFY-unsplash (1).jpg';
import imgTimeTableGroup from '../assets/images/pages/courses/discount-spanish-lessons/get-help-inside-outside-64f0f22b35a3e5b8a58db5fae679b072.png';
import ImgQuotes from '../assets/images/favicon/quotes.inline.svg';

import '../assets/styles/pages/discount-spahish-lessons.scss';

const tableData = {
  course: 'Course',
  from: 'From',
  to: 'To',
  timetable: 'Timetable',
  price: 'Price',
};
const a1 = `
<div><ul><li><span>Introduce yourself and meet other people.</span></li></ul><ul><li><span>Describe people, objects, places, situations or weather.</span></li></ul><ul><li><span>Express your likes and dislikes.</span></li></ul><ul><li><span>Describe your family and friends.</span></li></ul><ul><li><span>Describe the market, clothes and shopping.</span></li></ul><ul><li><span>Make suggestions.</span></li></ul><ul><li><span>Time, dates, numbers.</span></li></ul><ul><li><span>Describe the seasons.</span></li></ul><ul><li><span>Describe transport, cities, countries.</span></li></ul><ul><li><span>Describe possessions.</span></li></ul></div>
`;
const a2 = `
<div><ul><li><span>Describe routines and habits.</span></li></ul><ul><li><span>Describe and narrate experiences and events in the past.</span></li></ul><ul><li><span>Discuss diet and lifestyle.</span></li></ul><ul><li><span>Use polite forms and give advice.</span></li></ul><ul><li><span>Talk about the future and plans.</span></li></ul><ul><li><span>Give directions and instructions.</span></li></ul><ul><li><span>Describe appearances.</span></li></ul></div>
`;
const b1 = `
<div"><ul><li><span>Describe experiences, anecdotes, stories and tales of the past.</span></li></ul><ul><li><span>Express your desires and feelings.</span></li></ul><ul><li><span>Talk about hypothetical situations.</span></li></ul><ul><li><span>Discuss fictional stories.</span></li></ul><ul><li><span>Express and justify your opinions.</span></li></ul><ul><li><span>Describe emotions and give advice.</span></li></ul><ul><li><span>Describe and respond to the news and current events.</span></li></ul><ul><li><span>Describe achievements.</span></li></ul><ul><li><span>Discuss the environment and social issues.</span></li></ul></div>
`;
const b2 = `
<div"><ul><li><span>Participate in discussions and describe your viewpoint.</span></li></ul><ul><li><span>Retell stories in other peoples’ words.</span></li></ul><ul><li><span>Express hypothetical conditions.</span></li></ul><ul><li><span>Give detailed and accurate explanations of preferences, descriptions, personal experiences.</span></li></ul><ul><li><span>Tell anecdotes.</span></li></ul><ul><li><span>Describe wishes and regrets.</span></li></ul><ul><li><span>Discuss future hopes and plans.</span></li></ul><ul><li><span>Discuss the arts and culture.</span></li></ul></div>
`;
const c1 = `<div"><ul><li><span>Express yourself with ease, spontaneity and effectiveness in social, academic and professional scenarios.</span></li></ul><ul><li><span>Make jokes and use double meanings or irony.</span></li></ul><ul><li><span>Be able to understand a wide variety of discourses: literary texts, films, scholarly articles, television, etc.</span></li></ul><ul><li><span>Produce clear speech, fluent and well-structured with a controlled use of organisational patterns, connectors and cohesive devices.</span></li></ul><ul><li><span>Speculate and use vague language.</span></li></ul><ul><li><span>Discuss controversy.</span></li></ul><ul><li><span>Use language of persuasion, frustration, criticism, evaluation, negotiation.</span></li></ul></div>`;
const c2 = `<div"><ul><li><span>Take part in any conversation or discussion with ease.</span></li></ul><ul><li><span>Be able to understand with ease virtually everything you hear or read.</span></li></ul><ul><li><span>Use idiomatic expressions and colloquialisms.</span></li></ul><ul><li><span>Express yourself fluently and accurately while conveying nuances.</span></li></ul><ul><li><span>Learn to overcome communication problems with discretion.</span></li></ul></div>`;

const DiscountSpanishLessons = ({ data }) => {
  const allPrismicCourses = data?.allPrismicCourses.edges;
  return (
    <Layout
      title="Discount Spanish Lessons | ELE Barcelona"
      description="Lessons to learn and practice Spanish with trainee teachers. Practical training. Small groups. Improve your fluency with fun and dynamic classes. Sign up today!"
    >
      <div className="discount-spahish-lessons">
        <CFS
          title="Discount Spanish with trainee teachers"
          description="Learn Spanish in just 4 hours per week"
          subsctribeBtn="Sign up now"
          subscribeBtnRoute={EN_SIGN_UP}
          schedule={{ table: '1-12 months', clock: '4-6 hours per week' }}
        />
        <section className="second-section c-section-py-80">
          <div className="container">
            <div className="row">
              <div className="col">
                <h2 className="second-section__title c-title-32">
                  Looking for a great way to kickstart your Spanish?
                </h2>
                <p className="second-section__description c-text-18 c-text-18--gray-100">
                  ELE Barcelona is a Spanish teacher training school based in Barcelona city centre.
                  If you’re looking for a cost-effective way of learning and practising your Spanish
                  you can sign up to our Spanish lessons with trainee teachers. All classes are
                  prepared under the supervision of the tutor and the teachers are trained in a
                  student-centred, communicative style of teaching, meaning you’ll get plenty of
                  practice speaking in each class.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-5">
                <div className="left">
                  <img src={imgSecSection} alt="Happy person" />
                </div>
              </div>
              <div className="col-lg-7">
                <div className="right">
                  <h3 className="right__title">This course is ideal for you if:</h3>
                  <div className="wrapper">
                    <Checkmark>
                      You plan to stay in Barcelona long-term and want to boost your fluency
                    </Checkmark>
                    <Checkmark>You want to learn in a small-group setting</Checkmark>
                    <Checkmark>You are looking for a cost-effective way to study Spanish</Checkmark>
                    <Checkmark>You love meeting new people and exploring cultures</Checkmark>
                  </div>
                </div>
              </div>
            </div>
            <div className="row extra-info-cards">
              <div className="col-lg-3 col-sm-6">
                <UniversalCard
                  title="Small-group learning"
                  description="Maximum of 8 students per class"
                  img={imgGr}
                />
              </div>
              <div className="col-lg-3 col-sm-6">
                <UniversalCard
                  title="Fun, dynamic lessons"
                  description="All carefully prepared under the supervision of our senior tutor"
                  img={imgEpeea}
                />
              </div>
              <div className="col-lg-3 col-sm-6">
                <UniversalCard
                  title="Modern classrooms"
                  description="Interactive whiteboards, Mac computers and high-speed wifi throughout"
                  img={imgAm}
                />
              </div>
              <div className="col-lg-3 col-sm-6">
                <UniversalCard
                  title="Central location"
                  description="Only 2min walking from Passeig de Gracia metro"
                  img={imgApyol}
                />
              </div>
            </div>
          </div>
        </section>
        <section className="third-section c-section-includes c-section-py">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="block_l">
                  <div className="block_l__img">
                    <img src={imgReviewer} alt="" className="img-main" />
                  </div>
                  <div className="block_l__text">
                    <ImgQuotes />
                    <h3 className="c-text-18">I really enjoyed these courses</h3>
                    <p>
                      I recommend them to anyone who wants to improve their Spanish in a relaxed,
                      multicultural environment. The classes are excellent value, and even though
                      the teachers are learning, they spend hours preparing fun lessons with a
                      variety of interesting topics. I highly recommend it!
                      <div className="line"></div>
                      <strong>Harry Lawrence</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="block_r">
                  <h2 className="c-title-34">What’s included?</h2>
                  <div className="c-benefit-list block_r__benefits">
                    <div className="benefit">
                      <Checkmark>
                        At least <strong>4 hours</strong> of class per week
                      </Checkmark>
                    </div>
                    <div className="benefit">
                      <Checkmark>Free matriculation</Checkmark>
                    </div>
                    <div className="benefit">
                      <Checkmark>Spanish teachers from all over the world</Checkmark>
                    </div>
                    <div className="benefit">
                      <Checkmark>All learning material</Checkmark>{' '}
                    </div>
                    <div className="benefit">
                      <Checkmark>Well-prepared classes</Checkmark>
                    </div>
                    <div className="benefit">
                      <Checkmark>
                        Possibility of doing private classes, with no additional cost
                      </Checkmark>
                    </div>
                  </div>
                  <div className="block_r__price block_r__price--full">
                    <h3 className="c-text-13">4 hours per week:</h3>
                    <h3 className="c-title-32">80€</h3>
                  </div>
                  <div className="block_r__price block_r__price--early-bird">
                    <h3 className="c-text-13">6 hours per week:</h3>
                    <h3 className="c-title-32">80€</h3>
                  </div>
                  <div className="block_r__btns">
                    <Link href={EN_SIGN_UP} className="c-btn c-btn--green">
                      Sign up now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="fourth-section c-section-py-80">
          <div className="container">
            <div className="row">
              <div className="col">
                <CourseSection
                  title="Next courses"
                  cardData={allPrismicCourses}
                  tableData={tableData}
                  btnRoute={EN_SIGN_UP}
                  isEnglish
                />
              </div>
            </div>
          </div>
        </div>

        <section className="eigth-section  c-faqs-section">
          <div className="container">
            <div className="cta-block row">
              <div className="col">
                <div className="cta">
                  <div className="left">
                    <h2 className="cta__title">Level test</h2>
                    <p className="cta__description">
                      Before you start studying you will have to do a quick oral level test with our
                      coordinator, to make sure you are in the best group for your level. Once you
                      sign up we will contact you to organise a suitable time.
                    </p>
                  </div>
                  <div className="right">
                    <Link to={EN_SIGN_UP} className="c-btn c-btn--green">
                      Sign up now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-12">
                <h3 className="c-title-34">Course Curriculum</h3>
                <p className="c-text-18">
                  The specific topics covered will vary based on your level, consult the list to see
                  what you will learn.
                </p>

                <div className="group-photo">
                  <img src={imgTimeTableGroup} alt="imgTimeTableGroup" />
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <Collapse title="A1 Beginner" description={a1} />
                <Collapse title="A2 Lower intermediate" description={a2} />
                <Collapse title="B1 Intermediate" description={b1} />
                <Collapse title="B2 Upper intermediate" description={b2} />
                <Collapse title="C1 Advanced" description={c1} />
                <Collapse title="C2 Proficient" description={c2} />
              </div>
            </div>
          </div>
        </section>
        <section className="internal-section c-section-py-80">
          <div className="container ">
            <div className="row">
              <div className="col text-center">
                <h2 className="c-title-34 mb-4">Our Students’ Reviews</h2>
                <a
                  href="https://www.youtube.com/@Elebarcelona"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="c-btn c-btn--green"
                >
                  Check YouTube Reviews
                </a>
              </div>
            </div>
          </div>
        </section>
        <div className="c-faqs-section c-section-py-80">
          <div className="container ">
            <div className="row">
              <div className="col">
                <h3 className="c-faqs-section__title c-title-34">Frequently Asked Questions</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-12">
                <Collapse
                  title="I am a complete beginner. Does the teacher speak my language?"
                  description={
                    'Yes. Although our Spanish instructors conduct all courses in Spanish, they all speak some English and can assist with translations as needed. Due to our level system, you will be placed with other new learners.'
                  }
                />
                <Collapse
                  title="Can you help me overcome my fear of speaking Spanish?"
                  description={
                    'Absolutely. It is normal to be nervous, shy, or scared when speaking a new language in a new city. Our conversational teaching style is designed to give you more confidence in the classroom, but also on the streets of Barcelona (and the rest of the Spanish speaking world).'
                  }
                />
                <Collapse
                  title="What will the classes be like?"
                  description={
                    'The specific content varies based on your level, but all classes are fun, dynamic, and taught in small groups (maximum 8 students), with a focus on speaking and listening. Teachers incorporate activities from the textbook and the interactive whiteboard into all lessons.'
                  }
                />
              </div>
              <div className="col-lg-6 col-12">
                <Collapse
                  title="What is the communicative approach?"
                  description={
                    'The communicative approach is a teaching method that emphasises students interacting in real, ‘authentic’ scenarios in order to learn a language. Nowadays it is considered one of the most effective methods of language learning. You can read more about it <a target="_blank" class="green-link" href="https://en.wikipedia.org/wiki/Communicative_language_teaching">here.</a>'
                  }
                />
                <Collapse
                  title='What does "immersion" mean?'
                  description={
                    'Immersion means that from the moment you enter the classroom you will be encouraged to speak only in Spanish. You may be wondering, <i>“how can I speak and understand Spanish if I’ve just started learning?”</i> The answer is that our qualified and professionally trained teachers use a range of techniques in order to help a student understand, without using any translation.'
                  }
                />
                <Collapse
                  title="Which level is best for me?"
                  description={
                    'You will complete a thorough level test with one of our teachers in order to place you in the most appropriate level. This consists of an initial online test, followed by a speaking and listening test completed at the school. As soon as we have your results we will let you know which group is best for you. If you feel the level is too easy or too difficult, we can easily move you to a different one. You can start the <a target="_blank" class="green-link" href="https://quiz.tryinteract.com/#/5ee8ea170b5f230014a640a5/q/1">online level test here.</a>'
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allPrismicCourses(
      filter: {
        data: { show_on_these_pages: { elemMatch: { page: { eq: "discount-spanish-lessons" } } } }
      }
      sort: { order: ASC, fields: data___from }
    ) {
      edges {
        node {
          id
          data {
            name {
              text
            }
            from(formatString: "D MMMM YYYY", locale: "es")
            to(formatString: "D MMMM YYYY", locale: "es")
            price
            is_price_per_month
            timetable
          }
        }
      }
    }
  }
`;

export default DiscountSpanishLessons;
